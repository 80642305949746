import { BlockScrollStrategy, NoopScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GalleryGroup } from 'src/app/models/gallery-group';
import { WorkItem } from 'src/app/models/work-item';

@Component({
  selector: 'app-gallery-elements',
  templateUrl: './gallery-elements.component.html',
  styleUrls: ['./gallery-elements.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryElementsComponent implements OnInit {

  @Input() public group : GalleryGroup;

  constructor(public dialog: MatDialog, private viewportRuler : ViewportRuler) { }

  ngOnInit(): void {
  }


   /**
   * Aufruf wenn das Bild geöffnet wird
   * 
   * @param item  Bild-Objekt
   * @param index Id des Bildes im Galerie-Service
   */ 
  public openImage(item : WorkItem, index : number) : void {
    const dialogRef = this.dialog.open(ImageDialog, {
      maxWidth: "96vw",
      maxHeight: "99vh",
      data: {workItem: item, group: this.group,index: index},
      //scrollStrategy: new NoopScrollStrategy()
      //scrollStrategy: new BlockScrollStrategy(this.viewportRuler, document)
    });
  }

}

export class ImageDialogData {
  workItem : WorkItem;
  group : GalleryGroup;
  index : number;
}

@Component({
  selector: 'image-dialog',
  template: '<div class="titleContainer"><div class="closeIcon" (click)="dialogRef.close()">x</div><div class="title" mat-dialog-title>{{data.workItem.getName()}}</div></div>\
  <div class="dialogContent" mat-dialog-content>\
  <div class="bigImageContainer">\
  <div class="leftArrow arrow" [class.hiddenArrow]="!hasNextImage(false)" (click)="onChangeImage(false)"><img class="imgArrow" src=\'assets/arrow-left.png\'/></div>\
  <div class="rightArrow arrow" [class.hiddenArrow]="!hasNextImage(true)" (click)="onChangeImage(true)"><img style="margin-left: 0.3em;" class="imgArrow" src=\'assets/arrow-right.png\'/></div>\
  <img class="bigImage" [src]="data.group.getItemPath(data.workItem)"/></div>\
  <div class="subtitle">{{data.workItem.getDescription()}}</div>\
  </div>',
  styles: [".bigImage { width: auto; object-fit: contain; object-position: center; overflow-x: scroll; z-index: 1001; max-height: 74vh;}\
            .bigImageContainer {display: flex; justify-content: center; position: relative}\
            .subtitle {margin-top: 1em;}\
            .title {font-size: 18pt;}\
            .dialogContent {margin-top: 0.5em; position: relative;}\
            .closeIcon {float: right; margin-left: 1em; cursor: pointer; font-size: 18pt} .closeIcon:hover {color: #E3AE94}\
            .leftArrow {position: absolute; top: 50%; left: 1em; bottom: 50%; }\
            .rightArrow {position: absolute; top: 50%; right: 1em; bottom: 50%; }\
            .leftArrow:hover {background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)); filter: brightness(100%) invert(1)}\
            .rightArrow:hover {background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)); filter: brightness(100%) invert(1)}\
            .hiddenArrow {display:none !important;}\
            .arrow {height: 32px; width: 32px;z-index: 10000; display: block; border-radius: 99px; background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));}\
            .imgArrow {margin-top: 0.25em; margin-left: 0.2em;width: 24px; height: 24px; filter: brightness(100%) invert(1)}"]
})
export class ImageDialog {
  constructor(
    public dialogRef: MatDialogRef<ImageDialog>,
    @Inject(MAT_DIALOG_DATA) public data : ImageDialogData,
  ) {}

  /**
   * Wird aufgerufen wenn das Bild nach Links oder rechts gewechselt wird
   * 
   * @param isRight wahr - Rechts / falsch Links
   */
  onChangeImage(isRight : boolean) {
    if(this.hasNextImage(isRight)) {
      this.data.index = isRight ? this.data.index + 1 : this.data.index -1
      this.data.workItem = this.data.group.getItems()[this.data.index];
    }
  }

  /**
   * Prüft ob das angezeigte Bild ein nächstes Bild hat
   * 
   * @param isRight wahr - Rechts / falsch Links
   */
  hasNextImage(isRight : boolean): boolean {
    if(!isRight) {
      return this.data.index > 0;
    } else {
      return this.data.index <= (this.data.group.getItems().length - 2);
    }
  }

  /**
   * Aufruf wenn ins Nichts geklickt wird
   */
  onNoClick(): void {
    this.dialogRef.close();
  }
}
