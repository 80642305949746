import { Component, OnInit } from '@angular/core';
import { GalleryGroup } from 'src/app/models/gallery-group';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less']
})
export class NewsComponent implements OnInit {

  public gallery : GalleryGroup;

  constructor(private galleryService : GalleryService) { }

  ngOnInit(): void {
    this.gallery = this.galleryService.getGroups()[3];
  }

}
