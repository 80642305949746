import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './sites/contact/contact.component';
import { GalleryComponent } from './sites/gallery/gallery.component';
import { HomeComponent } from './sites/home/home.component';
import { ImpressComponent } from './sites/impress/impress.component';
import { NewsComponent } from './sites/news/news.component';
import { PrivacyComponent } from './sites/privacy/privacy.component';

const routes: Routes = [
  
  {path: 'gallery/:group', component: GalleryComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'impress', component: ImpressComponent},
  {path: 'privacy', component: PrivacyComponent},
  //{path: 'news', component: NewsComponent},
  {path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
