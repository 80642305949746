import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { WorkItem } from './models/work-item';
import { GalleryService } from './services/gallery.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Bildhauer Dirk Wachtel';

  constructor(private gallery : GalleryService,
     private router : Router) {
    this.registrerGalleryGroups();
  }

  isActiveRoute(r : string) : boolean {
    return this.router.url == r;
  }

  /**
   * Registriert alle Galleriegruppen
   */
  private registrerGalleryGroups() {
    let auftragsarbeiten = this.gallery.registerGalleryGroup(1, "Auftragsarbeiten", "assets/auftragsarbeiten");
    auftragsarbeiten.registerItems([      
      new WorkItem("Kopf von Hermes", "kopf.hermes.jpg", ""),
      new WorkItem("Konsolen mit Kränzen, Palais Hoym, Dresden", "konsolen.jpg", "Sandstein"),
      new WorkItem("Sandsteinskulptur Tübingen", "saeulen-ornament.jpg", "Ausführung"),
      new WorkItem("Kartusche Sandstein, Palais Hoym Dresden", "modell.ausfuehrung.jpg", "Entwurf, Ausführung"),
      new WorkItem("Vase aus Sandstein, Palais Hoym Dresden ", "kartusche.vase.jpg", "Entwurf, Ausführung"),
      new WorkItem("Isis aus Sandstein, Stadtvilla Dresden", "isis.sandstein.jpg", "Bildhauerkopie"),
      new WorkItem("Kreuzblume", "kreuzblumen02.jpg", "Kreuzblume, Grabstein"),
      new WorkItem("Kreuzblume", "kreuzblumen01.jpg", "Kreuzblume, Grabstein"),
      new WorkItem("Kapitell Sandstein, Garnisonkirche Potsdam", "kartusche01.jpg", "Bildhauerkopie"),
      new WorkItem("Kartusche, Garnisonkirche Potsdam", "kapitelle02.jpg", "Bildhauerkopie, anteilig"),
      new WorkItem("Kopf von Herkules, Kromlau", "kopf_herkules.jpg", "aufmodelliert"),
      new WorkItem("Kapitell Sandstein, Alte Post Wittenberg", "kapitell_post01.jpg", "Bildhauerkopie"),
      new WorkItem("Kapitell Sandstein, Alte Post Wittenberg", "kapitell_post02.jpg", "Bildhauerkopie"),
      new WorkItem("Tetris", "tetris.jpg", "Belgischer Mamor"),
      new WorkItem("Kreuzblume, Radebaul", "kreuzblume04.jpg", "Sandstein"),
      new WorkItem("Sandsteinskulptur, Schlüterhof Berliner Schloss", "sandsteinskulptur.jpg", ""),
      new WorkItem("Sandsteinfigur (Liebe), Innenhof Berliner Schloss", "sandsteinfigur01.jpg", ""),      
      new WorkItem("Adlerkartusche", "aussenportal01.jpg", "Bildhauerkopie, anteilig"),
      new WorkItem("Detail Kartusche, Außenportal Berliner Stadtschloss", "aussenportal02.jpg", "Bildhauerkopie"),
      new WorkItem("Fama, Außenportal Berliner Stadtschloss", "aussenportal03.jpg", "Bildhauerkopie"),
      new WorkItem("Eckkartusche, Berliner Schloss", "eckkartusche.jpg", "Bildhauerkopie, anteilig"),
      new WorkItem("Innenportalkartusche, Berliner Stadtschloss", "innenportal01.jpg", "Bildhauerkopie, anteilig"),
      new WorkItem("Innenportal Reliefs, Berliner Stadtschloss ", "innenportal02.jpg", "Bildhauerkopien"),
      new WorkItem("Innenportal, Eckkonsole, Berliner Schloss", "innenportal03.jpg", "Bildhauerkopie"),    
      new WorkItem("Kollosalkapitell, Berliner Schloss", "kollosalkapitell01.jpg", "Bildhauerkopie"),
      new WorkItem("Kollosalkapitell, Berliner Schloss", "kollosalkapitell02.jpg", "Bildhauerkopie"),
      new WorkItem("Kapitelle, Ornamentbänder, Rampische Straße, Dresden", "fassade.jpg", "Entwurf, Ausführung"),
      new WorkItem("Mamorkapitell, Neustrehlitz Schlosspark ", "bildhauerkopie01.jpg", "Bildhauerkopie"),
      new WorkItem("Trophäe, Johanneum Dresden", "trophaee01.jpg", "Bildhauerkopie, anteilig"),
      new WorkItem("Kartusche, Schloss Übigau Dresden", "kartusche02.jpg", "Bildhauerkopie"),
      new WorkItem("Mandorla, Muschelkalk, Stadtkirche Mühlhausen", "mandorla01.jpg", "(Baldachin, Ziersockel, Höhlenszene), Entwurf und Ausführung"),
      new WorkItem("Fantasiegebirge, Görlitz, Hollywoodprod. (Grand Budapest Hotel)", "gebirge.jpg", "Styropor Gipsputz"),
      new WorkItem("Schwedensäule, Rügen, Sandstein", "schwedensaeule.jpg", "Bildhauerkopie, Mitarbeit"),
      new WorkItem("Katusche, Schloßstraße Dresden", "kartusche03.jpg", "Bildhauerkopie"),
      new WorkItem("Bauteil Renaissance Erker", "bauteil01.jpg", "Frankfurt"),
      new WorkItem("Kapitelle, Halbestädter Dom", "kapitell04.jpg", ""),
      new WorkItem("Fialen, Krabben, Halbestädter Dom", "kapitelle05.jpg", ""),
      new WorkItem("Schloss Albrechtsberg Dresden", "albrechtsberg01.jpg", "Bildhauerkopie"),
      new WorkItem("Vierung, Schloss Albrechtsberg Dresden", "albrechtsberg02.jpg", ""),
      new WorkItem("Georgenbau Dresdner Schloss", "sandsteinornament01.jpg", "Bildhauerkopie"),
      new WorkItem("Georgenbau Dresdner Schloss", "sandsteinornamente02.jpg", "Bildhauerkopie"),
      new WorkItem("Georgenbau Dresdner Schloss", "sandsteinornamente03.jpg", "Bildhauerkopie"),
      new WorkItem("Kapitelle, Dresdner Schloss", "kapitelle06.jpg", "Bildhauerkopie"),
      new WorkItem("Ergänzung, Staatstheater Wiesbaden ", "wiesbaden-staatstheater.jpg", "Modell, Ausführung"),
      new WorkItem("Kreuzblume, Stralsund", "kreuzblume03.jpg", "Bildhauerkopie"),
      new WorkItem("Polizeipräsidium Dresden", "beil.jpg", "Vierungen"),
      new WorkItem("Schloss Dornberg", "kartusche04.jpg", "Modell, Ausführung"),
      new WorkItem("Universitätsbibliothek Leipzig", "sandsteinornamente04.jpg", "Bildhauerkopie"),
      new WorkItem("Universitätsbibliothek Leipzig", "sandsteinornament05.jpg", "Bildhauerkopie"),
      new WorkItem("Kunstakademie Dresden, Ergänzungen", "ergaenzungen01.jpg", "Modell, Ausführung"),
      new WorkItem("Brunnenfigur, Halle", "brunnenfigur.jpg", "Rekonstruktion, Abguss"),
      new WorkItem("Balkonfigur, Neues Rathaus Leipzig", "kalkstein01.jpg", "Ergänzungen, Ausführung"),
      new WorkItem("Kartusche, Neues Rathaus Leipzig", "kalkstein02.jpg", "Ergänzungen, Ausführung"),
      new WorkItem("Kapitelle, Schloss Leitzkau", "kapitelle07.jpg", "Bildhauerkopien"),
      new WorkItem("Schloss Meseberg", "rosenstein01.jpg", "Bildhauerkopie"),
      new WorkItem("Schloss Meseberg", "rosenstein02.jpg", "Bildhauerkopie"),
      new WorkItem("Schloss Meseberg", "rosenstein03.jpg", "Bildhauerkopie"),
      new WorkItem("Schloss Torgau", "relief01.jpg", "Bildhauerkopie"),
      new WorkItem("Brücke, Naumburg", "muschelornament.jpg", "Bildhauerkopie"),
      new WorkItem("Kunstprojekt Dresden", "kunstprojekt01.jpg", "Formenbau, Ausführung"),
      new WorkItem("Zeughaus, Berlin", "statue01.jpg", "Bildhauerkopie, anteilig"),
      new WorkItem("Bildergalerie Potsdam", "skulptur01.jpg", "Bildhauervierungen"),
      new WorkItem("Belvedere, Potsdam", "sandsteinornament06.jpg", "Bildhauervierungen"),
    ]);
    let freiearbeiten = this.gallery.registerGalleryGroup(2 ,"Freie Arbeiten", "assets/freiearbeiten");
    freiearbeiten.registerItems([
      new WorkItem("Joy", "maedchenkopf.jpg", "Marmor"),
      new WorkItem("Putto", "putto.jpg", "Stuck, bemalt, Gutshaus Heeselicht"),
      new WorkItem("Wolkenlampe", "wolkenlampe.jpg", "Plastiktüten, Gutshaus Heeselicht"),
      new WorkItem("Hermes (Schlüterwerkstatt)", "hermes.jpg", "Sandstein bemalt, Gutshaus Heeselicht"),
      new WorkItem("Sandsteinkopf", "sandsteinkopf01.jpg", "(Berliner Schloß) Kopie"),
      new WorkItem("Kissen, Popelau", "sandsteinkissen.jpg", "Sandstein"),
      new WorkItem("Tischdecke", "tischdecke.jpg", "Sandstein, bemalt, Gutshaus Heeselicht"),
      new WorkItem("Freie Nachbildung der Psyche von Arthur Volkmann", "maedchen.jpg", "Sandstein, bemalt"),
      new WorkItem("Jesusknabe", "knabe01.jpg", "Gips, bemalt"),
      new WorkItem("Maria", "madonna01.jpg", "Terrakotta, bemalt"),
      new WorkItem("Taube", "taube.jpg", "Styropor, Gips, bemalt"),
      new WorkItem("Gartenskulptur Adler mit Mäusen", "gartenskulptur01.jpg", "Sandstein"),
      new WorkItem("Kaminteil", "sandsteinkamin.jpg", "Sandstein"),
      new WorkItem("Putto", "putten01.jpg", "Kunststein"),
      new WorkItem("Putto", "putten02.jpg", "Kunststein"),
      new WorkItem("Putto", "putten03.jpg", "Kunststein"),
      new WorkItem("Hirschkopf", "hirschmedaillon.jpg", "Sandstein"),
      new WorkItem("Kissen", "marmorkissen.jpg", "Marmor"),
      new WorkItem("Kissenbank", "sandsteinbank.jpg", "Sandstein"),
      new WorkItem("Ei", "sandsteinei.jpg", "Sandstein"),
      new WorkItem("Pferdekopf", "pferdekopf.jpg", "Kunststein"),
      new WorkItem("Adlersäule", "adler.jpg", " Sandstein / Kunststein"),
      new WorkItem("Heeselichtermühlenrelief", "relief01.jpg", "Sandstein"),
      new WorkItem("Löwenkopf", "loewenkopf.jpg", "Sandstein"),
      new WorkItem("Mädchenbüste", "bueste.jpg", "Terrakotta, bemalt"),
      new WorkItem("Eule", "eule.jpg", "Sandstein"),
      new WorkItem("Johanna", "portraet01.jpg", "Kalkstein"),
      new WorkItem("Prof. Lorenzen", "portraet02.jpg", "Kalkstein"),
      new WorkItem("Gräfin Coselrelief", "cosel.jpg", "Sandstein"),
      new WorkItem("Puttokopf", "puttenkopf.jpg", "Sandstein"),
      new WorkItem("Joy", "portraet03.jpg", "Terrakotta, bemalt"),
      new WorkItem("Eike", "portraet04.jpg", "Serpentin"),
      new WorkItem("Mutter", "portraet05.jpg", "Terrakotta"),
      new WorkItem("Kopfstudie", "sandsteinkopf.jpg", "Sandstein"),
      new WorkItem("Kopfstudie", "marmorkopf.jpg", "Marmor"),      
    ]);
    let malerei = this.gallery.registerGalleryGroup(3, "Malerei und Zeichnungen", "assets/malerei");
    malerei.registerItems([
      new WorkItem("Heeselichter Kühe", "glueckliche.kuehe.jpg", "Öl auf Holz, 170 x 110"),
      new WorkItem("Drei Grazien nach Raffael", "drei-grazien.jpg", "Acryl auf Holz, 170 x 135"),
      new WorkItem("Kopie", "der-leser.jpg", "Öl auf Holz, 90 x 80"),
      new WorkItem("Gutshaus Heeselicht", "gutshaus.jpg", "Öl auf Holz, 80 x 60"),
      new WorkItem("Selbstporträt und Joy", "portraet01.jpg", "Öl auf Leinwanddruck, 100 x 100"),
      new WorkItem("Liselotte und Günter", "portraet02.jpg", "Öl auf Leinwanddruck, 120 x 80"),
      new WorkItem("Bobby", "bobby.jpg", "Öl auf Leinwanddruck, 120 x 70"),
      new WorkItem("Ines", "portraet03.jpg", "Öl auf Leinwand, 80 x 60"),
      new WorkItem("Elbtal", "landschaft01.jpg", "Acryl auf Holz, 180 x 80"),
      new WorkItem("Heeselichter Wiese", "landschaft02.jpg", "Acryl auf Holz, 170 x 80"),
      new WorkItem("Joy", "portraet04.jpg", "Öl auf Holz, 50 x 40"),
      new WorkItem("Joy und Mietze", "portraet05.jpg", "60 x 40"),
      new WorkItem("Kopie", "engel.jpg", "Öl auf Holz, 120 x 80"),
      new WorkItem("Ines im Klimtstil, Siegerbild bei Wien sucht den neuen Klimt", "portraet06.jpg", "Öl auf Leinwand, 120 x 70"),
      new WorkItem("Kopie", "kopie01.jpg", "Öl auf Holz, 110 x 80"),
      new WorkItem("Tarzan", "tarzan.jpg", "Tusche auf Pappe"),
      new WorkItem("Frau Bitterlich", "bitterlich.jpg", "Tusche auf Papier"),
      new WorkItem("Tom", "tom.jpg", "Tusche auf Pappe"),     
    ]);

    let neu = this.gallery.registerGalleryGroup(4, "Neue Arbeiten", "assets/", false);
    neu.registerItems([
      new WorkItem("-", "auftragsarbeiten/saeulen-ornament.jpg", ""),
      new WorkItem("-", "freiearbeiten/maedchen.jpg", ""),
      new WorkItem("-", "malerei/drei-grazien.jpg", ""),
      new WorkItem("-", "malerei/der-leser.jpg", ""),
      new WorkItem("-", "malerei/gutshaus.jpg", ""),
    
      
    ]);
  }
}
