import { Component, OnInit } from '@angular/core';
import { GalleryGroup } from 'src/app/models/gallery-group';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-gallery-groups',
  templateUrl: './gallery-groups.component.html',
  styleUrls: ['./gallery-groups.component.less']
})
export class GalleryGroupsComponent {

  constructor(private gallery : GalleryService) { }

  public getGroups() : GalleryGroup[] {
    return this.gallery.getGroups().filter(g => g.isOnHomepage());
  }

  public getFirstImagePath(group : GalleryGroup) : string {
    let items = group.getItems();
    if(items.length != 0 && items[0]) {
      return group.getItemPath(items[0]);
    }
  }
}
