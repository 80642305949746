<h1>Kontakt zum Bildhauer</h1>

<p>Dirk Wachtel<br />
Bildhauer<br />
Scheibenm&uuml;hlenstra&szlig;e 25<br />
01833 Stolpen</p>

<table>
    <tr><td><b>Telefon:</b></td><td>035975 / 81530</td></tr>
    <tr><td><b>Mobil:</b></td><td>0151 / 55 777 245</td></tr>
</table><br>
<img src="assets/dirk.wachtel.kontakt.jpg" class="kontakt" alt="Bildhauer Dirk Wachtel vor einem Sandsteinkapitell"><br>

<p></p>
<p></p>
<p></p>
<p>Wo Sie viele seiner Arbeiten sehen können … <a href="https://www.gutshaus-heeselicht.de" target="_blank">Gutshaus Heeselicht</a></p>