<h1>
    Impressum
</h1>
<h3>Angaben gemäß § 5 TMG</h3>
<p>Dirk Wachtel<br />
Bildhauer<br />
Scheibenm&uuml;hlenstra&szlig;e 25<br />
01833 Stolpen</p>

<h3>Kontakt</h3>
<p>Telefon: 035975 / 81530<br>
E-Mail: bildhauer@dirk-wachtel.de</p>

<h3>Umsatzsteuer-ID</h3>
<p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br>
210/285/03390</p>

<h3>Redaktionell verantwortlich</h3>
<p>Dirk Wachtel<br>
Scheibenm&uuml;hlenstra&szlig;e 25<br />
01833 Stolpen</p>

<h3>EU-Streitschlichtung</h3>
<p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
  
    <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
<p></p>
<h3>Bildrechte:</h3>
<p>Bereich "Aufragsarbeiten": © Bildhauer Dirk Wachtel, Stolpen, ausser wenn das entsprechende Bild separat gekennzeichnet ist. <br>
    Bereich "Freie Arbeiten": © Bildhauer Dirk Wachtel, Stolpen<br>
     Bereich "Malerei und Zeichnungen": © Bildhauer Dirk Wachtel, Stolpen</p>
