/**
 * Klasse für Arbeiten
 * 
 * @author Michael Schäfer
 */
export class WorkItem {

    private name : string = "Unbekannt";
    private path : string = "";
    private description : string = null;

    constructor(name : string, path : string, description : string) {
        this.name = name;
        this.path = path;
        this.description = description;
    }

    public getName() : string {
        return this.name;
    }

    public getPath() : string {
        return this.path;
    }

    public getDescription() : string {
        return this.description;
    }

}