<div class="root">
  <div class="grid">
    <div>
      <app-cover></app-cover>
    </div>
    <div class="header">
      <div class="titel-mobil">
        <span class="job">Bildhauer</span><br>
        <span class="name">Dirk Wachtel</span>
      </div>
      <ul class="menuList">
        <li class="menuEntry" [class.menuEntryActive]="isActiveRoute('/')" routerLink="/">
          Startseite
        </li>
        <!--<li class="menuEntry" [class.menuEntryActive]="isActiveRoute('/news')" routerLink="/news">
          Aktuelles
        </li>-->
        <li class="menuEntry" [class.menuEntryActive]="isActiveRoute('/contact')" routerLink="/contact">
          Kontakt
        </li>
      </ul>
    </div>
    <div class="content"> 
      <router-outlet></router-outlet>
      <div class="footer">
        <a routerLink="/impress">Impressum</a>
        <a routerLink="/privacy">Datenschutz</a>
        <div class="copyright">© Bildhauer Dirk Wachtel Stolpen</div>
      </div>
    </div>
  </div>
</div>