import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './sites/home/home.component';
import { ContactComponent } from './sites//contact/contact.component';
import { ImpressComponent } from './sites/impress/impress.component';
import { PrivacyComponent } from './sites/privacy/privacy.component';
import { GalleryComponent } from './sites/gallery/gallery.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { CoverComponent } from './components/cover/cover.component';
import { GalleryGroupsComponent } from './components/gallery-groups/gallery-groups.component';
import { NewsComponent } from './sites/news/news.component';
import { GalleryElementsComponent } from './components/gallery-elements/gallery-elements.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    ImpressComponent,
    PrivacyComponent,
    GalleryComponent,
    CoverComponent,
    GalleryGroupsComponent,
    NewsComponent,
    GalleryElementsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
