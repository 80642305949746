import { WorkItem } from "./work-item";

/**
 * Klasse für die Gallerien
 * 
 * @author Michael Schäfer
 */
export class GalleryGroup {

    private ident : number;
    private name : string;
    private path : string;

    private showHomepage = true;

    private items : WorkItem[] = [];

    constructor(ident : number, name : string, path: string, showHomepage : boolean = true) {
        this.ident = ident;
        this.name = name;
        this.path = path;
        this.showHomepage = showHomepage;
    }

    public getName() : string {
        return this.name;
    }

    public getPath() : string {
        return this.path;
    }

    public getItems() : WorkItem[] {
        return this.items;
    }

    public getIdent() : number {
        return this.ident;
    }

    public isOnHomepage() :boolean {
        return this.showHomepage;
    }

    public registerItem(item : WorkItem) {
        this.items.push(item);
    }

    public registerItems(items : WorkItem[]) {
        items.forEach(i => {
            this.items.push(i);
        })
    }

    public getItemPath(item : WorkItem) {
        return this.getPath() + "/" + item.getPath();
    }
 }