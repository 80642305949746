import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GalleryGroup } from 'src/app/models/gallery-group';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less']
})
export class GalleryComponent implements OnInit {

  public group : GalleryGroup;

  constructor(private route : ActivatedRoute,
     private gallery : GalleryService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.group = this.gallery.getGroups().find(g => {
        if(g.getIdent() && g.getIdent() == Number.parseInt(paramMap.get('group'))) {
          return g;
        }
      });;
    });
  }

}
