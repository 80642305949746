import { Injectable } from '@angular/core';
import { GalleryGroup } from '../models/gallery-group';

/**
 * Service für die Registrierung der Gallerien
 * 
 * @author Michael Schäfer
 */
@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  private groups : GalleryGroup[] = [];

  constructor() { }

  /**
   * Registriert eine neue Galleriegruppe
   * 
   * @param name Name der Gallerie
   */
  public registerGalleryGroup(id : number, name : string, path : string, showOnHomepage : boolean = true) : GalleryGroup {
    let group = new GalleryGroup(id, name, path, showOnHomepage);
    this.groups.push(group);
    return group;
  }

  /**
   * Ermittelt alle Galleriegruppen
   */
  public getGroups() : GalleryGroup[] {
    return this.groups;
  }
}
