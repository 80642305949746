<div class="coverImg"></div>
<div class="nameDiv">
    <div class="upper"></div>
    <div class="lower"></div>
    <div class="nameBereich">
        <div class="nameParent">
            <div class="name">
                <div class="fullname">Dirk Wachtel</div><br>
                <div class="position">Bildhauer</div>
            </div>
        </div>
    </div>
</div>
